import React from "react";
import { GoRepoForked } from "react-icons/go";
import { GiWorld } from "react-icons/gi";
import { Dimmer, Image } from "semantic-ui-react";
import "semantic-ui-css/components/dimmer.css";
import "semantic-ui-css/components/image.css";

export default class PortfolioCard extends React.Component {
  state = { active: false };

  handleShow = () => this.setState({ active: true });
  handleHide = () => this.setState({ active: false });

  render() {
    const { active } = this.state;
    const { stacked } = this.props;
    const repo = () => {
      if (this.props.repo !== "") {
        return (
          <a
            className="other-link"
            href={this.props.repo}
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="btn btn-info port-button">
              <GoRepoForked /> Repo
            </button>
          </a>
        );
      }
    };

    const liveSite = () => {
      if (this.props.liveSiteURL !== "") {
        return (
          <a
            className="other-link"
            href={this.props.liveSiteURL}
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="btn btn-success port-button">
              <GiWorld /> Live Site
            </button>
          </a>
        );
      }
    };

    const content = (
      <div>
        <h3>{this.props.title}</h3>
        <div className="card-content-container">
          <p className="card-content">{this.props.description}</p>
        </div>
        <div className="other-links">
          {repo()}
          {liveSite()}
        </div>
      </div>
    );

    const cardStyle = {
      // color: 'blue',
      // backgroundImage: "url(" + this.props.imageURL + ")",
    };

    return (
      <>
        <div className={stacked ? "portfolio-card-stacked" : "portfolio-card"}>
          <Dimmer.Dimmable
            as={Image}
            dimmed={active}
            dimmer={{ active, content }}
            onMouseEnter={this.handleShow}
            onMouseLeave={this.handleHide}
            size="medium"
            style={cardStyle}
            src={this.props.imageURL}
          />
        </div>
      </>
    );
  }
}
